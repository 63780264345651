<template>
  <b-card class="mb-0">
    <h2>{{ $t('service.additional_informations.title') }}</h2>
    <validation-observer ref="additionalInformationFormRef" tag="form" @submit.prevent="onSubmit">
      <!--DATE START-->
      <div class="my-2 validation-required">
        <label>{{ $t('service.additional_informations.validity_dates') }}</label>
        <b-row class="mb-1">
          <b-col md="4" sm="6">
            <app-datepicker
              id="startAt"
              v-model="product.mainVariant.visibilityStartAt"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :max="product.mainVariant.visibilityEndAt"
              :placeholder="$t('common.start_at')"
              rules="required"
            />
          </b-col>
          <!--DATE END-->
          <b-col md="4" sm="6">
            <app-datepicker
              id="endAt"
              v-model="product.mainVariant.visibilityEndAt"
              :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
              :min="product.mainVariant.visibilityStartAt"
              :placeholder="$t('common.end_at')"
              rules="required"
            />
          </b-col>
        </b-row>
      </div>

      <div v-if="product.typology === 'fuel'" class="my-2">
        <enum-select
          id="fuelType"
          v-model="product.mainVariant.fuelType.id"
          :clearable="false"
          :label="$t('service.additional_informations.fuel_type')"
          :reduce="option => option.value"
          enum-class="FuelType"
          :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
          required
        />
      </div>

      <!--ATTRIBUTE -->
      <b-card class="border">
        <b-card-title class="d-flex justify-content-between align-items-center">
          <div class="d-flex">{{ $tc('service.additional_informations.characteristic', 2) }}</div>
          <div
            id="addAttribute"
            v-b-tooltip.hover.left="!normalizeAttributesRest.length ? $t('service.additional_informations.alert.cant_add_more_characteristics') : ''"
          >
            <app-btn-add v-if="$route.name !== 'productShow' || $route.name === 'operatorProductShow'" :disabled="!normalizeAttributesRest.length" @click="addAttribute" />
          </div>
        </b-card-title>

        <div class="repeater-form mb-3">
          <b-row v-for="(currentCharacteristic, index) in product.mainVariant.attrs" :key="index" ref="rowCharacteristic" class="d-flex justify-start mb-1">
            <b-col :class="{ 'mt-2 pt-1': currentCharacteristic.type === 'input' }" md="3">
              <b-form-group
                :label="
                  currentCharacteristic.attribute.required || !normalizeAttributesRest.length ? getTradName(currentCharacteristic.attribute.name) + ' :' : ''
                "
                :label-for="`attrValue-${index}`"
                class="mb-0"
                label-size="lg"
              >
                <v-select
                  v-if="!currentCharacteristic.attribute.required && normalizeAttributesRest.length"
                  v-model="product.mainVariant.attrs[index]"
                  :append-to-body="true"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :get-option-label="attr => attr.attribute.name[$store.state.appConfig.lang]"
                  :options="normalizeAttributesRest"
                  :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
                />
              </b-form-group>
            </b-col>

            <b-col md="7">
              <validation-provider v-if="currentCharacteristic.type === 'select'" v-slot="{ errors }" rules="required">
                <v-select
                  v-model="product.mainVariant.attrs[index].selectValue"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :get-option-label="value => getTradName(value.name)"
                  :input-id="`attrValue-${index}`"
                  :options="getOptions(product.mainVariant.attrs[index], index)"
                  :placeholder="getTradName(currentCharacteristic.attribute.name)"
                  :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
                  append-to-body
                />
                <small v-if="errors[0]" class="text-danger">
                  <!-- remove {field} display if no name -->
                  {{ errors[0].replace(' {field}', '') }}
                </small>
              </validation-provider>
              <validation-provider v-if="currentCharacteristic.type === 'choice'" v-slot="{ errors }" rules="required">
                <v-select
                  v-model="product.mainVariant.attrs[index].choiceValues"
                  :clearable="false"
                  :close-on-select="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :get-option-label="value => getTradName(value.name)"
                  :input-id="`attrValue-${index}`"
                  :options="getOptions(product.mainVariant.attrs[index], index)"
                  :placeholder="getTradName(currentCharacteristic.attribute.name)"
                  append-to-body
                  :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
                  multiple
                />
                <small v-if="errors[0]" class="text-danger">
                  <!-- remove {field} display if no label -->
                  {{ errors[0].replace(' {field}', '') }}
                </small>
              </validation-provider>
              <app-form-text-input-itn
                v-if="currentCharacteristic.type === 'input'"
                v-model="product.mainVariant.attrs[index].inputValues"
                :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
                :default-lang="$store.state.appConfig.lang"
                :label="getTradName(currentCharacteristic.attribute.name)"
                :languages="$store.state.auth.activelang"
                :show-label="false"
                form-name="attributes-input"
              />
            </b-col>

            <!--BTN ACTION-->
            <!-- Remove Button -->
            <b-col :class="['mx-auto', { 'mt-2 pt-1': currentCharacteristic.type === 'input' }]" md="1">
              <b-button-group class="ml-2">
                <b-button
                  v-if="!currentCharacteristic.attribute.required || ($route.name !== 'productShow' && $route.name !== 'operatorProductShow')"
                  v-b-tooltip.hover.left="$t('action.delete')"
                  v-ripple.400
                  size="md"
                  variant="outline-primary"
                  @click="deleteAttribute(index)"
                >
                  <font-awesome-icon class="text-danger" icon="trash" />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <!-- DROPZONE -->
      <b-form-group v-if="$route.params.typology !== 'fuel' && $route.name !== 'productShow' && $route.name !== 'operatorProductShow'" :label="$t('service.additional_informations.images')" label-for="files">
        <b-overlay :show="dropZoneLoading" spinner-medium class="mt-1">
          <div class="text-muted font-small-3">
            <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span> <span>png, jpeg</span> |
            <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>2 Mo</span>
          </div>

          <app-drop-zone
            id="upload-files"
            ref="uploadFiles"
            :options="dropzoneOptions"
            @vdropzone-queue-complete="iconUploaded = true"
            @vdropzone-removed-file="removePlainFile($event)"
            @vdropzone-file-added="sendPlainFile($event)"
          />
        </b-overlay>
      </b-form-group>

      <app-form-text-editor-itn
        v-model="product.mainVariant.description"
        form-name="product-description"
        :readonly="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
        :default-lang="$store.state.appConfig.lang"
        :label="$t('service.additional_informations.description')"
        :languages="$store.state.auth.activelang"
      />

      <footer-form-service v-if="$route.name === 'productShow' || $route.name === 'operatorProductShow'" previous next :disabled="saveLoader" @click:next="$emit('next-tab')" @click:previous="$emit('previous-tab')" />
      <footer-form-service v-else previous save save-next :disabled="saveLoader" @click:previous="$emit('previous-tab')" @click:save-next="nextRedirection = true" />
    </validation-observer>
  </b-card>
</template>

<script>
import { computed, defineComponent, inject, reactive, ref } from '@vue/composition-api'
import { pick, remove } from 'lodash'

import AttributesCollection from '@/collections/AttributesCollection'
import imgUpload from '@/helpers/imgUpload'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'
import AppDropZone from '@/components/AppDropZone.vue'
import AppBtnAdd from '@/components/AppBtnAdd.vue'
import AppFormTextEditorItn from '@/components/AppFormTextEditorItn.vue'
import AppFormTextInputItn from '@/components/AppFormTextInputItn.vue'
import AppDatepicker from '@/components/AppDatepicker.vue'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'AdditionalInformation',

  components: {
    FooterFormService,
    AppDropZone,
    AppBtnAdd,
    AppFormTextEditorItn,
    AppFormTextInputItn,
    AppDatepicker,
  },

  mixins: [imgUpload],

  setup(_props, ctx) {
    const { $i18n, $set, $store, toaster, $options } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')

    // ref template
    const additionalInformationFormRef = ref(null)
    const uploadFiles = ref(null)

    const attributes = reactive(new AttributesCollection())
    const normalizeAttributes = ref([])
    const normalizeAttributesRest = computed(() =>
      // eslint-disable-next-line implicit-arrow-linebreak
      normalizeAttributes.value.filter(attr => !product.value.mainVariant.attrs.map(attrProduct => attrProduct.attribute.id).includes(attr.attribute.id)))

    const getTradName = name => $options.filters.trans(name)

    const transformFormatAttrsListToAttrProduct = attr => {
      const selectedValues = {
        select: { key: 'selectValue', value: [] },
        choice: { key: 'choiceValues', value: [] },
        input: { key: 'inputValues', value: { en: '', fr: '' } },
      }

      return {
        ...pick(attr, 'type'),
        attribute: pick(attr, 'id', 'name', 'required'),
        [selectedValues[attr.type].key]: selectedValues[attr.type].value,
      }
    }

    product.value.fetchPlain(ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(() => {
      attributes.fetch({ categoryId: product.value.mainCategory.id }).then(() => {
        normalizeAttributes.value = attributes._attributes.items.map(attr => transformFormatAttrsListToAttrProduct(attr))
        const normalizeAttributesRequired = normalizeAttributes.value
          .filter(attr => !product.value.mainVariant.attrs.map(attrProduct => attrProduct.attribute.id).includes(attr.attribute.id))
          .filter(attrRest => attrRest.attribute.required === true)

        product.value.mainVariant.attrs = product.value.mainVariant.attrs.concat(normalizeAttributesRequired)
      })
      const activeLangs = $store.state.auth.activelang

      activeLangs.forEach(lang => {
        $set(product.value.mainVariant.description, lang.locale, product.value.mainVariant.description[lang.locale] || '')
      })

      if (product.value.mainVariant.files) {
        product.value.mainVariant.files.forEach(val => {
          const file = {
            id: val.id,
            size: 2000000,
            name: val.filePath,
          }
          uploadFiles.value.manuallyAddFile(file, val.fileUrls.default)
        })
      }
    })

    const dropZoneLoading = ref(false)
    const iconUploaded = ref(false)
    const nextRedirection = ref(false)
    const saveLoader = ref(false)

    const dropzoneOptions = {
      autoProcessQueue: false,
      url: 'https://localhost',
      dictDefaultMessage: $i18n.t('dropzone.drop_here'),
      thumbnailWidth: 250,
      maxFilesize: 2000000,
      acceptedFiles: 'image/*',
      addRemoveLinks: true,
      clickable: true,
    }

    const removePlainFile = file => {
      remove(product.value.mainVariant.files, f => file.id === f.id)
    }

    const sendPlainFile = file => {
      if (file.size > dropzoneOptions.maxFilesize) {
        toaster($i18n.t('file.max_file_size_reached'), 'danger', 'AlertTriangleIcon')
        uploadFiles.value.removeFile(file)
        return
      }

      dropZoneLoading.value = true
      iconUploaded.value = false

      const formData = new FormData()
      formData.append('temporary_file', file)

      postFileRequest(formData)
        .then(response => {
          product.value.mainVariant.files.push({
            id: response.data.id,
          })
        })
        .finally(() => {
          iconUploaded.value = true
          dropZoneLoading.value = false
        })
    }

    const getOptions = currentCharacteristic => {
      if (!attributes._attributes.items) return []
      const common = attributes._attributes.items.find(attr => attr.id === currentCharacteristic.attribute.id)
      if (common) return common.values
      return []
    }

    const addAttribute = () => {
      if (product.value.mainVariant.attrs.length < attributes._attributes.items.length) {
        product.value.mainVariant.attrs.push(normalizeAttributesRest.value[0])
      }
    }

    const deleteAttribute = index => {
      product.value.mainVariant.attrs.splice(index, 1)
    }

    const onSubmit = () => additionalInformationFormRef.value.validate().then(validForm => {
      if (validForm) {
        saveLoader.value = true
        product.value
          .patchPlain()
          .then(() => {
            if (nextRedirection.value) {
              $emit('next-tab')
              nextRedirection.value = false
            }
          })
          .finally(() => {
            saveLoader.value = false
          })
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    })

    return {
      product,
      uploadFiles,
      additionalInformationFormRef,
      attributes,
      getTradName,
      normalizeAttributes,
      normalizeAttributesRest,
      saveLoader,
      dropZoneLoading,
      iconUploaded,
      sendPlainFile,
      removePlainFile,
      nextRedirection,
      dropzoneOptions,
      getOptions,
      addAttribute,
      deleteAttribute,
      onSubmit,
    }
  },
})
</script>

<style lang="scss">
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.ql-container.ql-snow {
  min-height: 200px;
}
</style>
