import globalApi from '@/request/globalApi/globalApi'
import axios from 'axios'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/attribute'

export const fetchAttributesRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchAttributeRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const postAttributeRequest = (attribute, message) => globalApi.post(END_POINT, attribute, { message })

export const patchAttributeRequest = (attribute, message) => globalApi.patch(`${END_POINT}/${attribute.id}`, attribute, { message })

export const deleteAttributeRequest = (id, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}`, { message }))

export const uploadAttributeCSV = csvFile => globalApi.post(`${process.env.VUE_APP_API_BASE_URL}api/import/attribute`, csvFile, {headers: { 'Content-Type': 'multipart/form-data' },})

// TODO: create instance for download
const token = `Bearer ${localStorage.getItem('token')}` || ''
const selectedOrganizationId = localStorage.getItem('selectedOrganization')
  && JSON.parse(localStorage.getItem('selectedOrganization')).id
const lang = localStorage.getItem('lang')
export const exportAttributeCSV = () => axios.get(`${process.env.VUE_APP_API_BASE_URL}api/export/attribute`, {
  headers: { Accept: 'text/csv', 'Content-Type': 'text/csv', Authorization: token, selectedOrganizationId, lang },
}).then(response => {
  const blob = new Blob(
    [response.data],
    { type: 'text/csv' },
  )
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'attributes.csv' // TODO dynamic name when instance created
  link.click()
})